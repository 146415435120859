import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  breakpoint = new BehaviorSubject<string>('ipad');
  constructor() {}

  onResize(size) {
    if (size < 767) {
      this.breakpoint.next('mobile');
    } // Mobile
    if (size > 767 && size < 1024) {
      this.breakpoint.next('ipad');
    } // Ipad
    if (size > 1024) {
      this.breakpoint.next('desktop');
    } // Desktop
  }

  // breakpoint$(): Observable<string> {
  //   return this.breakpoint.asObservable().pipe(distinctUntilChanged());
  // }
}
